import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { mainApi } from "../../providers/api";
import FormControl from "../../inputs/FormControl";
import { ShowConfirm, ShowWarning } from "../../providers/alert";
import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const CATEGORIES = ["cat9", "cat1", "cat2", "cat4", "cat5", "cat10", "cat11"];

const TransparencyForm = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { transparency_id: id } = useParams();

	const [loading, setLoading] = useState(id);
	const [readonly, setReadonly] = useState(id);

	const [initialValues, setInitialValues] = useState({
		name: { mn: "", en: "" },
		content: { mn: "", en: "" },
		attachment: [],
		category: "",
		tags: [],
	});

	useEffect(() => {
		if (id && readonly) fetchItem(id);
	}, [id, readonly]);

	const fetchItem = async (id) => {
		try {
			setLoading(true);

			const { data } = await mainApi(`transparency/${id}`);

			setInitialValues(data);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteItem = async () => {
		try {
			setLoading(true);

			await mainApi({
				method: "DELETE",
				url: `transparency/${id}`,
			});

			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const saveItem = async (values) => {
		try {
			setLoading(true);

			await mainApi({
				method: id ? "PUT" : "POST",
				url: id ? `/transparency/${id}` : `/transparency`,
				data: values,
			});

			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={Yup.object({
				title: Yup.object({
					mn: Yup.string().required("Гарчиг Монгол хэлээр оруулна уу"),
					en: Yup.string().required("Гарчиг Англи хэлээр оруулна уу"),
				}),
				category: Yup.object().required("Ангилал сонгоно уу"),
				attachment: Yup.array().min(1, "Зураг оруулна уу"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				saveItem(values);
				setSubmitting();
			}}
		>
			<div className="p-5 border shadow-md flex flex-col gap-5 bg-white">
				<h1 className="bordered flex justify-between items-center">
					{id ? "Ил тод байдал" : "Ил тод байдлын мэдээлэл шинээр бүртгэх"}
					{id && (
						<div className="flex gap-2">
							<button
								type="button"
								className="red"
								onClick={async () => {
									const confirm = await ShowConfirm(
										"Энэ мэдээллийг устгахдаа итгэлтэй байна уу"
									);
									if (confirm.isConfirmed) deleteItem();
								}}
								disabled={loading}
							>
								<FaTrash></FaTrash>
							</button>
							<button
								disabled={loading}
								type="button"
								onClick={() => {
									setReadonly(!readonly);
									if (!readonly) setInitialValues();
								}}
							>
								{readonly ? (
									<>
										<FaEdit></FaEdit>
									</>
								) : (
									<>
										<FaTimes></FaTimes>
									</>
								)}
							</button>
						</div>
					)}
				</h1>
				<Form className="gap-5 flex flex-col">
					<Field
						component={FormControl}
						label="Ангилал"
						name="category"
						fetch={loading}
						readonly={readonly}
						type="select"
						options={CATEGORIES.map((v) => ({
							value: v,
							label: t(`transparency_cat.${v}`),
						}))}
					/>
					<Field
						component={FormControl}
						label="PDF файл"
						name="attachment"
						fetch={loading}
						readonly={readonly}
						type="file"
						accept={["pdf"]}
						axio={mainApi}
						count={1}
						height={150}
						limit={5}
					/>
					<div className="grid grid-cols-2 gap-5">
						<Field
							component={FormControl}
							label="Гарчиг /MN/"
							name="title.mn"
							fetch={loading}
							readonly={readonly}
							type="text"
						/>
						<Field
							component={FormControl}
							label="Гарчиг /EN/"
							name="title.en"
							fetch={loading}
							readonly={readonly}
							type="text"
						/>
					</div>
					<Field
						component={FormControl}
						label="Нийтлэх огноо"
						name="published"
						fetch={loading}
						readonly={readonly}
						time={false}
						type="date"
					/>
					<Field
						component={FormControl}
						name={id ? "Хадгалах" : "Шинээр бүртгэх"}
						fetch={loading}
						readonly={readonly}
						type="submit"
						containerClass="self-end primary"
					/>
				</Form>
			</div>
		</Formik>
	);
};

export default TransparencyForm;
